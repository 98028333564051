:root {
    --main-background: rgb(19, 18, 24);
    --box-background: rgb(36, 35, 43);
    --primary-font-color: #64626f;
    --secondary-font-color: #9f9ea6;
    --component-color: #00bcd4;
    --hover-color: #201f28;
    --selection: #f6ce6f;
  }
  
  body {
    margin: 0;
    box-sizing: border-box;
    background: var(--main-background);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  
  .felx-column {
    display: flex;
    flex-direction: column;
  }
  
  .pointer {
    cursor: pointer;
  }
  
  #root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  h4 {
    color: var(--primary-font-color);
    text-align: center;
    margin-top: 0;
  }
  
  button {
    width: 100%;
    border: 2px solid var(--component-color);
    height: 40px;
    background-color: var(--main-background);
    color: var(--component-color);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
  }
  
  button:hover {
    background: var(--hover-color);
  }
  
  .main-container {
    margin: 2rem;
    width: 100%;
  }
  .passblock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--box-background);
    height: 70px;
    padding: 0.4rem 1.5rem;
    position: relative;
  }
  
  .passblock input {
    display: none;
  }
  
  .passblock__string {
    color: var(--secondary-font-color);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    word-break: break-word;
  }
  
  .notification {
    position: absolute;
    right: 2px;
    top: 0;
    font-size: 13px;
    background: var(--secondary-font-color);
    padding: 2px 6px;
    border-radius: 3px;
  }
  
  .passblock__icon {
    color: var(--component-color);
    padding: 9px 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.5s;
  }
  
  .passblock__icon:hover {
    background: var(--main-background);
  }
  
  .configblock {
    margin-top: 1rem;
    padding: 1.5rem;
    background: var(--box-background);
    color: var(--secondary-font-color);
  }
  
  .range-text {
    float: right;
    color: var(--component-color);
    margin-right: 12px;
  }
  
  .form-group:not(:first-child) {
    margin-top: 1.5rem;
  }
  
  .form-group .range {
    margin-top: 1rem;
  }
  
  .form-group input[type="range"] {
    width: 100%;
    height: 5px;
    outline: none;
    background: var(--main-background);
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.2);
  }
  
  .box {
    background: var(--main-background);
    height: 50px;
    padding: 0 1.5rem;
  }
  
  .strength {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .strength__name {
    font-size: 12px;
  }
  
  .strength__indication {
    display: flex;
  }
  
  .strength__indication-status {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .strength__indication-bar {
    display: flex;
  }
  
  .strength__indication-bar div {
    height: 16px;
    width: 5px;
    margin-right: 5px;
    background-color: var(--main-background);
    border: 1px solid var(--secondary-font-color);
  }
  
  .strength__indication-bar.weak div:nth-child(-n + 1) {
    background: var(--selection);
    border-color: var(--selection);
  }
  
  .strength__indication-bar.fair div:nth-child(-n + 2) {
    background: var(--selection);
    border-color: var(--selection);
  }
  
  .strength__indication-bar.good div:nth-child(-n + 3) {
    background: var(--selection);
    border-color: var(--selection);
  }
  
  .strength__indication-bar.strong div:nth-child(-n + 4) {
    background: var(--selection);
    border-color: var(--selection);
  }
  
  @media only screen and (min-width: 600px) {
    .main-container {
      width: 550px;
    }
  }
  